/* Interior.css */

.interior-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
  margin-top: 40px;
}

.interior-header h1 {
  font-size: 3.0rem;
  margin-bottom: 10px;
  color: #333;
  font-family: 'poppins';

}

.interior-header p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #555;
  font-family: 'poppins';

}

.interior-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 50px;
}

.interior-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(71, 99, 103);
  transition: transform 0.2s;
}

.interior-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'poppins';

}

.interior-card p {
  font-size: 0.8rem;
  color: #555;
  font-family: 'poppins';

}

.interior-cta {
  margin-top: 50px;
  
}

.interior-cta h2 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-family: 'poppins';

}

.interior-cta p {
  font-size: 1.0rem;
  margin-bottom: 30px;
  color: #666;
  font-family: 'poppins';

}

.interior-cta button {
  padding: 20px 30px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.0rem;
  font-family: 'poppins';
}

.interior-cta button:hover {
  background-color: #444;
}
