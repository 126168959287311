* {
    box-sizing: border-box;
}

.table-container {
    margin: 40px auto;
    width: 90%; /* Make it responsive */
    max-width: 1200px;
    overflow-x: auto; /* Handle overflow on small screens */
    font-family: 'Poppins', sans-serif; /* Ensure Poppins font is applied */
}

.responsive-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden; /* Keep the border-radius intact */
}

/* Table Header Style */
.responsive-table thead th {
    background-color: #000;
    color: #fff;
    padding: 15px;
    font-weight: 500;
    font-size: 1rem;
}

/* Table Body Style */
.responsive-table tbody td {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 0.95rem;
    color: #333;
}

/* Remove border from the last row */
.responsive-table tbody tr:last-child td {
    border-bottom: none; /* Remove border from the last row */
}

/* General styles for smaller screens */
@media (max-width: 768px) {
    .responsive-table {
        width: 100%; /* Ensure it fills the container */
    }

    .responsive-table tbody tr {
        display: block; /* Display rows as blocks */
        margin-bottom: 15px; /* Space out rows */
        border: 1px solid #e0e0e0; /* Add border around each block for clarity */
        border-radius: 8px;
        background-color: #fafafa; /* Background for each row */
        padding: 10px; /* Padding for blocks */
    }

    .responsive-table tbody td {
        display: block; /* Each cell as a block */
        width: 100%; /* Full width */
        padding: 10px; /* Adjusted padding */
        text-align: left; /* Ensure text aligns to the left */
    }
}

/* Additional adjustments for devices between 481px and 768px */
@media (min-width: 481px) and (max-width: 768px) {
    .responsive-table {
        width: 100%; /* Ensure it fills the container */
    }

    .responsive-table tbody tr {
        display: block; /* Each row remains a block */
        margin: 10px 0; /* Space out rows */
    }

    .responsive-table tbody td {
        display: block; /* Display cells as blocks for better readability */
        border: none; /* No border in mobile view */
        padding: 10px; /* Padding for spacing */
        text-align: left; /* Align text to left */
    }
}
