/* ModernNavbar.css */

.navbar {
    position: fixed;
    top: 20px; /* Distance from the top */
    left: 10.5%; /* Center horizontally */
    transform: translateX(-50%); /* Horizontal centering fix */
    background-color: #ffffff;
    padding: 15px 30px;
    border-radius: 25px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%; /* Responsive width */
    max-width: 1200px; /* Maximum width on larger screens */
    z-index: 1000;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 120px; /* Adjust logo size */
    height: auto;
    max-height: 60px; /* Limit height for consistent navbar size */
    padding-top: 0%;
    margin-top: 6px;
}

/* Navbar Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 30px;
    font-family: 'Poppins', sans-serif;  /* Ensure the font is loaded */
}

.nav-links li a {
    text-decoration: none;
    color: #555;
    font-size: 1rem;
    transition: color 0.3s ease, transform 0.2s;
}

.nav-links li a:hover {
    color: #009fd4;
    transform: translateY(-2px);
}

/* Hamburger Menu Icon */
.menu-icon {
    display: none; /* Hide by default */
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
}

.bar {
    width: 30px;
    height: 4px;
    background-color: #333;
    transition: 0.3s;
}

.open {
    transform: rotate(45deg); /* Rotate on open */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .navbar {
        top: 10px; /* Adjusts position for mobile */
        left: 3%; /* Center horizontally */
        transform: translateX(-50%); /* Maintain centering */
        width: 95%; /* Slightly reduce width */
        max-width: 450px; /* Limit size for mobile */
        padding-bottom: 15px;
        border-radius: 20px;
    }

    .logo {
        font-size: 1.0rem; /* Adjust logo size */
        margin: -14px; /* Adjust margin */
        padding-left: 8px; /* Adjust padding */
    }

    .menu-icon {
        display: flex; /* Show menu icon on mobile */
    }

    .nav-links {
        display: none; /* Hide by default */
        flex-direction: column;
        gap: 10px;
        position: absolute;
        top: 60px; /* Position links below navbar */
        right: 10px; /* Align to the right */
        background-color: #ffffff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        z-index: 999;
    }

    .nav-links.active {
        display: flex; /* Show links when active */
    }

    .nav-links li a {
        font-size: 0.9rem;
        padding: 8px;
    }
}
