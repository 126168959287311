/* Ticker.css */
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@400&display=swap');

.ticker-container {
    overflow: hidden; /* Prevent overflow */
    white-space: nowrap; /* Ensure text stays on one line */
    position: relative;
    width: 100%; /* Full width */
}

.ticker {
    display: inline-block;
    font-family: 'Martian Mono', monospace;
    font-size: 150px; /* Default font size */
    line-height: 1;
    padding: 30px 0;
    color: rgb(121, 120, 120);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .ticker {
        font-size: 110px;
    }
}

@media (max-width: 480px) {
    .ticker {
        font-size: 80px;
    }
}
